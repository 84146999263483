import React, { useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import Accordion from 'components/Accordion';
import Modal from 'components/Modal';
import useLayout from 'hooks/useLayout';
import { CalendarItem } from '../calendar.d';
import CalendarContext from '../CalendarContext';
import CalendarMiniature from '../CalendarMiniature';
import { getHours } from '../calendarHelpers';
import { CalendarTooltip, CalendarEventsList } from '../components';
import FloatingEvents from '../components/FloatingEvents';

interface DayViewProps extends React.HTMLAttributes<HTMLDivElement> {
  showCalendarMiniature: boolean;
}

type EventsPopupData = {
  target?: EventTarget | HTMLElement;
  items: CalendarItem[];
};

const DayView: React.FC<DayViewProps> = ({ showCalendarMiniature }) => {
  const layout = useLayout();
  const { calendarEvents, calendarData, selectedDate } = useContext(CalendarContext);
  const [eventsPopupData, setEventsPopupData] = useState<EventsPopupData>();
  const openEventsModal = Boolean(eventsPopupData);
  const hours = getHours();
  const eventCellHeight = layout === 'mobile' ? 40 : 44;
  const pixelsPerMinute = eventCellHeight / 60;

  const { deadlines, tasks, events } = useMemo(() => {
    const deadlines: CalendarItem[] = [];
    const tasks: CalendarItem[] = [];
    const events: CalendarItem[] = [];

    calendarEvents
      .filter(event => event.start.isSame(selectedDate, 'date'))
      .forEach(event => {
        switch (event.dateType) {
          case 'deadline':
            deadlines.push(event);
            break;
          case 'task':
            tasks.push(event);
            break;
          case 'event':
            events.push(event);
            break;
          default:
            break;
        }
      });

    return { events, tasks, deadlines };
  }, [calendarEvents, selectedDate]);

  switch (layout) {
    case 'mobile':
      return (
        <StyledDayViewMobile>
          {showCalendarMiniature && <CalendarMiniature events={calendarData} />}
          <Accordion
            disabled={deadlines.length === 0}
            label="DEADLINES"
            counter={deadlines.length || 'No deadlines scheduled for this day'}
            data-cy="accordeon_deadlines">
            <CalendarEventsList items={deadlines} />
          </Accordion>
          <Accordion
            disabled={tasks.length === 0}
            label="TASKS"
            counter={tasks.length || 'No tasks scheduled for this day'}
            data-cy="accordeon_tasks">
            <CalendarEventsList items={tasks} />
          </Accordion>

          <div className="hours">
            {hours.map(hour => (
              <div key={hour} className="hour">
                <span className="hour-text" data-cy={`hour_${hour}`}>
                  {dayjs().hour(hour).minute(0).format('h A')}
                </span>
              </div>
            ))}
            <FloatingEvents
              events={events}
              pixelsPerMinute={pixelsPerMinute}
              onEventClick={(e, event) => {
                if (openEventsModal) return;
                setEventsPopupData({ target: e.target, items: [event] });
              }}
            />
          </div>
          {eventsPopupData?.items?.length && (
            <Modal
              modalTitle="Event"
              open={openEventsModal}
              onClose={() => setEventsPopupData(undefined)}
              renderContainer={StyledEventsModalContainer}>
              <CalendarEventsList
                items={eventsPopupData.items}
                onEmpty={() => setEventsPopupData(undefined)}
              />
            </Modal>
          )}
        </StyledDayViewMobile>
      );
    case 'tablet':
      return (
        <StyledDayViewTablet className="day-view tablet">
          {showCalendarMiniature && <CalendarMiniature events={calendarData} />}
          <div className="tasks-deadlines">
            <Accordion
              disabled={deadlines.length === 0}
              label="DEADLINES"
              counter={deadlines.length || 'No deadlines scheduled for this day'}
              defaultOpen
              data-cy="accordeon_deadlines">
              <CalendarEventsList items={deadlines} />
            </Accordion>
            <Accordion
              disabled={tasks.length === 0}
              label="TASKS"
              counter={tasks.length || 'No tasks scheduled for this day'}
              defaultOpen
              data-cy="accordeon_tasks">
              <CalendarEventsList items={tasks} />
            </Accordion>
          </div>

          <div className="hours">
            {hours.map(hour => (
              <div key={hour} className="hour">
                <span className="hour-text">{dayjs().hour(hour).minute(0).format('h A')}</span>
              </div>
            ))}
            <FloatingEvents
              events={events}
              pixelsPerMinute={pixelsPerMinute}
              onEventClick={(_e, event) => {
                setEventsPopupData({
                  target: document.getElementById(`event-${event.id}`)!,
                  items: [event]
                });
              }}
            />
          </div>
          <CalendarTooltip
            target={eventsPopupData?.target}
            open={openEventsModal}
            tooltipTitle="Event"
            onClose={() => setEventsPopupData(undefined)}>
            <CalendarEventsList
              items={eventsPopupData?.items}
              onEmpty={() => setEventsPopupData(undefined)}
            />
          </CalendarTooltip>
        </StyledDayViewTablet>
      );
    case 'desktop':
      return (
        <StyledDayViewDesktop className="day-view desktop">
          <CalendarMiniature events={calendarData} />
          <div className="tasks-deadlines">
            <Accordion
              disabled={deadlines.length === 0}
              label="DEADLINES"
              counter={deadlines.length || 'No deadlines scheduled for this day'}
              defaultOpen
              data-cy="accordeon_deadlines">
              <CalendarEventsList items={deadlines} />
            </Accordion>
            <Accordion
              disabled={tasks.length === 0}
              label="TASKS"
              counter={tasks.length || 'No tasks scheduled for this day'}
              defaultOpen
              data-cy="accordeon_tasks">
              <CalendarEventsList items={tasks} />
            </Accordion>
          </div>

          <div className="hours">
            {hours.map(hour => (
              <div key={hour} className="hour">
                <span className="hour-text">{dayjs().hour(hour).minute(0).format('h A')}</span>
              </div>
            ))}
            <FloatingEvents
              events={events}
              pixelsPerMinute={pixelsPerMinute}
              onEventClick={(_e, event) => {
                setEventsPopupData({
                  target: document.getElementById(`event-${event.id}`)!,
                  items: [event]
                });
              }}
            />
          </div>
          <CalendarTooltip
            target={eventsPopupData?.target}
            open={openEventsModal}
            tooltipTitle="Event"
            onClose={() => setEventsPopupData(undefined)}>
            <CalendarEventsList
              items={eventsPopupData?.items}
              onEmpty={() => setEventsPopupData(undefined)}
            />
          </CalendarTooltip>
        </StyledDayViewDesktop>
      );
    default:
      return null;
  }
};

export default DayView;

const StyledDayViewMobile = styled.div`
  .day-view-miniature-controls {
    display: flex;
    margin-bottom: 8px;

    .button {
      margin: 0;
    }
    .current-date {
      margin: auto;
    }
  }

  .calendar-miniature {
    margin-bottom: 16px;
  }

  .accordion {
    border-bottom: none;

    .accordion-head {
      background: #f8f8f8;
      height: 32px;
      padding: 8px;
      border-radius: ${props => props.theme.misc.borderRadius};
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 8px;

      .label {
        margin-right: auto;
      }

      .counter {
        color: #000000;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .hours {
    position: relative;
    margin-top: 24px;

    .hour {
      box-sizing: border-box;
      height: 40px;
      border-top: 1px solid ${props => props.theme.colors.seashell};
      font-size: 10px;
      line-height: 12px;
      padding: 4px 0;
      color: ${props => props.theme.colors.grayDark};

      .hour-text {
        width: 30px;
      }
    }
  }

  .floating-events {
    padding-left: 30px;

    .timeline {
      margin-left: -27px;
    }
  }
`;

const StyledDayViewTablet = styled(StyledDayViewMobile)`
  display: grid;
  grid-template:
    'miniature miniature'
    'hours tasks-deadlines';
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 24px;

  .calendar-miniature {
    grid-area: miniature;
    margin: 0;
  }

  .hours {
    grid-area: hours;
    margin: 0;
    height: fit-content;
    border: 1px solid ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
    margin-left: 4px;

    .hour {
      height: 44px;
      padding: 8px;

      &:first-of-type {
        border-top: none;
      }
    }
  }

  .tasks-deadlines {
    grid-area: tasks-deadlines;
  }

  .floating-events {
    padding-left: 45px;

    .timeline {
      margin-left: -45px;
    }
  }
`;

const StyledDayViewDesktop = styled(StyledDayViewTablet)`
  grid-template: 'hours tasks-deadlines miniature';
  grid-template-columns: 1fr 1fr auto;

  .miniature {
    min-width: 300px;
    padding: 12px 0 10px;
    height: fit-content;
    border: 1px solid ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
  }
  .day-view-miniature-controls {
    margin: 0;
    padding: 0 4px;
  }
  .calendar-miniature {
    .cell {
      justify-content: center;
      border: 0;
      height: 100%;
      padding: 6px;

      &.today,
      &.active {
        border: 0 !important;
        .cell__dots {
          z-index: -1;
          opacity: 0;
          visibility: hidden;
        }
      }

      &.active {
        .cell__info {
          background: ${props => props.theme.colors.pink};
        }
        .cell__info:hover {
          border: 1px solid transparent;
        }
      }

      &.today {
        .cell__info {
          background: ${props => props.theme.colors.red};
          color: ${props => props.theme.colors.white};
        }
        .cell__title {
          color: ${props => props.theme.colors.white};
        }
      }
    }
    .cell__info {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: ${props => props.theme.misc.borderRadius};
      transition: ${props => props.theme.transitions.standart};
      border: 1px solid transparent;

      &:hover {
        border: 1px solid ${props => props.theme.colors.red};
      }
    }
    .cell__dots {
      opacity: 1;
      transition: ${props => props.theme.transitions.standart};
      bottom: 3px;
      left: 50%;
      transform: translateX(-50%);
    }

    .weeks {
      margin: 10px 0 0;
      .week.active {
        background: transparent;
      }
    }
    .weekdays {
      &:after {
        content: '';
        width: calc(100% - 24px);
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: ${props => props.theme.colors.gray};
      }
      .cell {
        padding: 6px 6px 15px;
        cursor: default;
      }
      .cell__info {
        color: ${props => props.theme.colors.grayCarbon};
      }
      .cell__info:hover {
        border: 1px solid transparent;
      }
    }
  }
`;

const StyledEventsModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    .xp-modal {
      border-radius: 0;
      margin: 0;
      width: 100%;
    }
  }
`;
