import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { ReactComponent as LoaderSVG } from './loader.svg';

const Loader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <StyledLoader className={clsx('loader', className)} {...props}>
      {children || <LoaderSVG />}
    </StyledLoader>
  );
};

export default Loader;

const StyledLoader = styled.span`
  display: inline-block;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 1;
    }
  }
`;
