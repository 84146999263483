import React from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import { BUSINESS_HOURS_START, BUSINESS_HOURS_END } from '../consts';

interface TimeLineProps extends React.HTMLAttributes<HTMLDivElement> {
  time: dayjs.Dayjs;
  pixelsPerMinute: number;
}

const businessHoursStartMoment = dayjs().hour(BUSINESS_HOURS_START).minute(0);

const TimeLine: React.FC<TimeLineProps> = React.memo(({ time, pixelsPerMinute, ...props }) => {
  const hours = time.hour();
  if (hours < BUSINESS_HOURS_START || hours > BUSINESS_HOURS_END) return null;
  const minutesFromStart = time.diff(businessHoursStartMoment, 'minute');
  const top = minutesFromStart * pixelsPerMinute;

  return (
    <StyledTimeline className="timeline" style={{ top }} {...props} data-cy="timeline">
      <svg width="5" height="5" viewBox="0 0 5 5" fill="none">
        <rect width="5" height="5" rx="2.5" fill="#DF1721" />
      </svg>
      <hr />
    </StyledTimeline>
  );
});

export default TimeLine;

const StyledTimeline = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  hr {
    border-width: 0 0 1px 0;
    border-color: ${props => props.theme.colors.red};
    margin: 0;
  }

  svg {
    position: absolute;
    top: -2px;
    left: -3.5px;
  }
`;
