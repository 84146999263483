import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import useOutsideClick from 'hooks/useOutsideClick';

type Option = {
  value: string;
  text: React.ReactNode;
};

interface IComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  options: Option[];
  target: (data: { onClick: (e: any) => void }) => JSX.Element;
  onOptionClick: (e: React.MouseEvent, value: string) => void;
}

const DropdownMenu: React.FC<IComponentProps> = ({ options, target, onOptionClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  useOutsideClick(containerRef, () => {
    if (open) setOpen(false);
  });

  const handleOpen = e => {
    e.stopPropagation();
    setOpen(value => !value);
  };

  const handleOptionClick = (e: React.MouseEvent, value: string) => {
    e.stopPropagation();
    if (onOptionClick) onOptionClick(e, value);
    setOpen(false);
  };

  return (
    <StyledDropdown ref={containerRef} className={clsx('dropdown-menu', { open })}>
      {target({ onClick: handleOpen })}
      {open && (
        <StyledDropdownOptions className="dropdown-options">
          {options.map(({ value, text }) => (
            <button
              key={value}
              value={value}
              onClick={e => handleOptionClick(e, value)}
              className="dropdown-option"
              data-cy={`button_menu_${value}`}>
              {text}
            </button>
          ))}
        </StyledDropdownOptions>
      )}
    </StyledDropdown>
  );
};

export default DropdownMenu;

const StyledDropdown = styled.div`
  position: relative;

  &.open > .button > .icon {
    fill: ${props => props.theme.colors.red};
  }
`;

const StyledDropdownOptions = styled.div`
  position: absolute;
  right: 0;
  margin-top: 6px;
  padding: 8px 0;
  background: white;
  border-radius: ${props => props.theme.misc.borderRadius};
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  z-index: 1;

  .dropdown-option {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 14px;
    font-size: 12px;
    line-height: 16px;
    border: none;
    background: none;
    cursor: pointer;
    white-space: nowrap;

    .icon {
      margin-right: 10px;
      fill: ${props => props.theme.colors.grayDark};
    }
  }
`;
