import React, { useMemo } from 'react';
import clsx from 'clsx';
import { capitalize } from 'lodash';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { CalendarItem } from '../calendar.d';

interface IComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  data: CalendarItem;
  onEdit: (value: CalendarItem) => void;
  onHide?: (value: CalendarItem) => void;
  onComplete?: (value: CalendarItem) => void;
}

const EventsListItem: React.FC<IComponentProps> = ({
  data,
  className,
  onEdit,
  onHide,
  onComplete,
  ...props
}) => {
  const {
    id,
    title,
    assignee,
    start,
    end,
    address,
    location,
    dateType,
    deadlineType,
    projectType,
    docId,
    status,
    editable,
    personalEvent,
    isExternal
  } = data;
  const startDate = start.isValid() ? start.format('hh:mm A') : null;
  const endDate = end?.isValid() ? end.format('hh:mm A') : null;
  const isCompletedTask = dateType === 'task' && status === 'Completed';
  const isClosingDate = deadlineType?.includes('closing_date');
  const isClosingEvent = dateType === 'event' && title.toLowerCase().includes('closing');
  const showCompleteButton = dateType === 'task';
  const showHideButton = ['task', 'deadline'].includes(dateType);
  const eventTitle = useMemo(
    () =>
      isClosingEvent ? (
        <span className={clsx('closing', { confirmed: docId })}>
          <span className="project-type">{capitalize(projectType)}&nbsp;</span>
          {title}
        </span>
      ) : (
          <span>{title}</span>
        ),
    [title, isClosingEvent, docId, projectType]
  );

  const handleButtonClick = async e => {
    e.stopPropagation();

    switch (e.target.name) {
      case 'edit':
        onEdit(data);
        break;
      case 'hide':
        onHide!(data);
        break;
      case 'complete':
        onComplete!(data);
        break;
      default:
        break;
    }
  };

  const assignedLabel =
    dateType !== 'deadline' ? (
      <>
        <Icon name="user" style={{ marginRight: '10px' }} />
        <span>{assignee || 'Not assigned'}</span>
      </>
    ) : null;
  const timeLabel =
    dateType === 'event' && startDate ? (
      <>
        <Icon name="time" />
        <span>{endDate ? `${startDate} - ${endDate}` : startDate}</span>
      </>
    ) : null;
  const locationLabel = location ? (
    <>
      <Icon name="location" /> <span>{location}</span>
    </>
  ) : null;
  const completedStatusLabel = isCompletedTask ? (
    <span className="task-status">
      <Icon name="check" />
    </span>
  ) : null;

  return (
    <StyledEventRow
      className={clsx(
        'schedule-row',
        dateType,
        { editable, completed: isCompletedTask, closing: isClosingDate },
        className
      )}
      {...props}
      data-cy={`event_${id}`}>
      <span className="title">{eventTitle}</span>
      <span className="project">{personalEvent ? 'Personal Event' : address}</span>
      <span className={clsx('assignee', { muted: !assignee })}>{assignedLabel}</span>
      <span className="time-info">{timeLabel}</span>
      {dateType === 'event' ? (
        <span className="location">{locationLabel}</span>
      ) : (
          completedStatusLabel
        )}
      {!isExternal && (
        <StyledScheduleButtons className="schedule-buttons">
          {showCompleteButton && (
            <Button
              simple
              name="complete"
              onClick={handleButtonClick}
              className={clsx({ done: isCompletedTask })}
              data-cy={`button_complete_task_${id}`}>
              <Icon name="check" />
            </Button>
          )}
          {showHideButton && (
            <Button simple name="hide" onClick={handleButtonClick} data-cy={`button_hide_${id}`}>
              {data.visible ? <Icon name="eye" /> : <Icon name="eye-crossed" />}
            </Button>
          )}
          {editable && (
            <Button simple name="edit" onClick={handleButtonClick} data-cy={`button_edit_${id}`}>
              <Icon name="pen" />
            </Button>
          )}
        </StyledScheduleButtons>
      )}
    </StyledEventRow>
  );
};

export default React.memo(EventsListItem);

const StyledEventRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 0.75fr 0.75fr 0.75fr;
  column-gap: 4px;
  padding: 4px 6px 4px 0;
  border-radius: ${props => props.theme.misc.borderRadius};
  cursor: pointer;

  .schedule-buttons {
    position: absolute;
    display: none;
    right: 6px;
    top: 4px;
  }

  &:hover {
    background: ${props => props.theme.colors.seashell};
    & .schedule-buttons {
      display: flex;
    }
  }

  .title {
    word-break: break-word;
  }

  .document {
    display: inline-flex;
    margin-left: 4px;
    padding: 2px;
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: ${props => props.theme.misc.borderRadius};

    .icon {
      margin-right: 0;
    }
  }

  .project-type {
    font-weight: 600;
  }

  &:not(.event) {
    .title {
      padding-left: 16px;
      font-weight: 500;
    }
  }

  &.task.completed {
    * {
      text-decoration: line-through;
    }

    .task-status {
      display: flex;
      align-self: center;
      justify-content: flex-end;

      .icon {
        fill: ${props => props.theme.colors.lightGreen};
      }
    }

    &:hover {
      .task-status {
        display: none;
      }
    }
  }

  &.event {
    padding-left: 4px;
    .title {
      & > span {
        padding: 0 4px 0 4px;
        border-radius: ${props => props.theme.misc.borderRadius};
        background: ${props => props.theme.colors.blue};
        color: #fff;
      }

      & > span.closing:not(.confirmed) {
        border: 1px solid ${props => props.theme.colors.blue};
        background: #fff;
        color: ${props => props.theme.colors.blue};
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 4px;
    left: 4px;
    top: 4px;
    bottom: 4px;
    border-radius: 4px;
  }

  &.closing {
    .title {
      padding-left: 4px;
      & > span {
        padding: 0 4px 0 4px;
        font-size: 12px;
        color: #fff;
        background: ${props => props.theme.colors.red};
        border-radius: ${props => props.theme.misc.borderRadius};
      }
    }
  }

  &.deadline:before {
    background: ${props => props.theme.colors.red};
  }

  &.task:before {
    background: ${props => props.theme.colors.lightGreen};
  }

  & > span {
    display: flex;
    align-items: center;
    align-self: flex-start;
  }

  & > span:not(.title) {
    overflow: hidden;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .project {
    color: ${props => props.theme.colors.grayDark};
  }

  .location {
    min-width: 0;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon {
    flex-shrink: 0;
    fill: ${props => props.theme.colors.grayDark};
  }

  .muted {
    color: ${props => props.theme.colors.grayDark};
  }

  &.editable:hover {
    .location {
      padding-right: 16px;
    }
  }
`;

const StyledScheduleButtons = styled.div`
  display: flex;
  align-items: center;

  .button.simple {
    width: 16px;
    height: 16px;
    margin-right: 9px;
    border-radius: ${props => props.theme.misc.borderRadius};

    &:hover {
      background: ${props => props.theme.colors.gray};
    }

    &:last-child {
      margin-right: 0;
    }

    &.done {
      .icon path {
        fill: ${props => props.theme.colors.lightGreen};
      }
    }
  }

  .icon {
    margin-right: 0;
    pointer-events: none;

    & > path {
      fill: ${props => props.theme.colors.grayDark};
    }
  }
`;
