/* eslint-disable no-plusplus */
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { BUSINESS_HOURS_START, BUSINESS_HOURS_END } from './consts';

dayjs.extend(isBetween);

export function getWeeks(date: dayjs.Dayjs, weeks = 6) {
  const startDate = dayjs(date).startOf('month').startOf('week');

  let incrementDay: Dayjs = startDate.clone();
  const result = Array(weeks)
    .fill([])
    .map(() => {
      const days: dayjs.Dayjs[] = [];
      for (let i = 0; i < 7; i++) {
        days.push(incrementDay);
        incrementDay = incrementDay.add(1, 'day');
      }

      return days;
    });

  const lastWeekFirstDate = result[weeks - 1][0];
  if (lastWeekFirstDate.isAfter(date, 'month') && lastWeekFirstDate.day() === 0) {
    result.length = weeks - 1;
  }

  return result;
}

export function getDaysRange(startDate: dayjs.Dayjs, range = 30) {
  const date = dayjs(startDate).startOf('day');
  let index = 0;
  const result: Array<Dayjs> = [];

  while (index < range) {
    result.push(date.clone().add(index, 'day'));
    index++;
  }

  return result;
}

export function getHours() {
  const hours: number[] = [];

  for (let index = BUSINESS_HOURS_START; index < BUSINESS_HOURS_END + 1; index++) {
    hours.push(index);
  }

  return hours;
}

export const getWeekdays = (date: dayjs.Dayjs) => {
  const startDate = dayjs(date).startOf('week');
  const weekDays: dayjs.Dayjs[] = [];

  for (let index = 0; index < 7; index++) {
    weekDays.push(startDate.clone().add(index, 'days'));
  }

  return weekDays;
};

export const baseRoute = '/calendar';
