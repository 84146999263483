import React from 'react';

import styled from '@emotion/styled';
import Loader from 'components/Loader';

const CalendarLoader: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => {
  return (
    <StyledCalendarLoader {...props}>
      <Loader />
    </StyledCalendarLoader>
  );
};

export default CalendarLoader;

const StyledCalendarLoader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
