import React, { useContext } from 'react';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import { WorkspaceContext } from 'workspace';
import { CalendarItem } from '../calendar.d';
import { EventsListItemMobile, EventsListItem } from '../components';
import { getDaysRange } from '../calendarHelpers';
import CalendarContext from '../CalendarContext';
import CalendarMiniature from '../CalendarMiniature';

interface IComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  showCalendarMiniature: boolean;
}

const ScheduleView: React.FC<IComponentProps> = ({ showCalendarMiniature }) => {
  const {
    setOpenEvent,
    selectedDate,
    calendarEvents,
    calendarData,
    toggleTaskCompleteStatus,
    toggleEventVisibility
  } = useContext(CalendarContext);
  const { setWorkspaceListingId, setWorkspaceTab } = useContext(WorkspaceContext);
  const layout = useLayout();
  const days = getDaysRange(selectedDate);

  const deadlines: CalendarItem[] = [];
  const tasks: CalendarItem[] = [];
  const events: CalendarItem[] = [];

  calendarEvents.forEach(event => {
    switch (event.dateType) {
      case 'deadline':
        deadlines.push(event);
        break;
      case 'task':
        tasks.push(event);
        break;
      case 'event':
        events.push(event);
        break;
      default:
        break;
    }
  });

  const dayDate = value => {
    const date = dayjs(value);
    const isToday = date.isSame(dayjs(), 'day');
    return isToday ? (
      <>
        <span>{date.format('D')}</span>
        {date.format('MMMM, dddd')}
      </>
    ) : (
        date.format('D MMMM, dddd')
      );
  };

  const handleOpenWorkspace = ({ propertyId, isExternal }) => {
    if (!propertyId || isExternal) return;
    setWorkspaceListingId(propertyId);
    setWorkspaceTab('tasks');
  };

  const emptyRow = <div className="empty">Nothing scheduled for this day</div>;

  const EventsListItemComponent = layout !== 'desktop' ? EventsListItemMobile : EventsListItem;

  const renderRow = (row, idx) => (
    <EventsListItemComponent
      data={row}
      key={`${row.dateType}_${row.id}_${idx}`}
      onEdit={setOpenEvent}
      onHide={toggleEventVisibility}
      onComplete={toggleTaskCompleteStatus}
      onClick={() => handleOpenWorkspace(row)}
    />
  );

  const renderRowGroup = day => {
    const rows: any[] = [];
    rows.push(
      events
        .filter(event => day.isSame(event.start, 'day'))
        .sort((a, b) => a.start.unix() - b.start.unix())
    );
    rows.push(deadlines.filter(deadline => day.isSame(deadline.start, 'day')));
    rows.push(tasks.filter(task => day.isSame(task.start, 'day')));
    const flattenRows = [].concat(...rows);

    if (!flattenRows.length) return emptyRow;
    return (
      <StyledScheduleRowGroup>
        {flattenRows.map((row, idx) => renderRow(row, idx))}
      </StyledScheduleRowGroup>
    );
  };

  switch (layout) {
    case 'mobile':
    case 'tablet':
      return (
        <StyledMobileScheduleView className="schedule-view mobile scrollable">
          {showCalendarMiniature && <CalendarMiniature events={calendarData} />}
          {days.map((day, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx} className="day" data-cy={`calendar_day_${idx}`}>
              <StyledDayTitle>{dayDate(day)}</StyledDayTitle>
              {renderRowGroup(day)}
            </div>
          ))}
        </StyledMobileScheduleView>
      );

    default:
      return (
        <StyledScheduleView className="schedule-view desktop scrollable">
          <div className="schedule-header">
            <div className="header-col">Date</div>
            <div className="header-col">Task</div>
            <div className="header-col">Project</div>
            <div className="header-col">Assigned to</div>
            <div className="header-col">Time</div>
            <div className="header-col">Location</div>
          </div>
          {days.map((day, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <StyledScheduleDay key={idx} data-cy={`calendar_day_${idx}`}>
              <StyledDayTitle>{dayDate(day)}</StyledDayTitle>
              {renderRowGroup(day)}
            </StyledScheduleDay>
          ))}
        </StyledScheduleView>
      );
  }
};

export default ScheduleView;

const StyledScheduleView = styled.div`
  position: relative;
  display: grid;
  column-gap: 12px;
  font-size: 12px;
  line-height: 16px;

  .schedule-header {
    position: sticky;
    top: 0;
    padding: 8px 0;
    grid-column: 1 / span 6;
    display: grid;
    grid-template-columns: 150px 1fr 1fr 0.75fr 0.75fr 0.75fr;
    column-gap: 12px;
    background: #fff;
    border-bottom: 1px solid #dadada;
    z-index: 1;
  }

  .header-col {
    color: ${props => props.theme.colors.grayDark};
  }
`;

const StyledMobileScheduleView = styled.div`
  padding-right: 4px;

  .miniature-controls {
    display: flex;
    margin-bottom: 8px;

    .button {
      margin: 0;
    }
    .current-date {
      margin: auto;
    }
  }

  .day {
    margin: 12px 0 24px 0;
    font-size: 12px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  h4 {
    margin-bottom: 8px;
  }

  .empty {
    padding-top: 8px;
    color: #8d8d8d;
  }
`;

const StyledScheduleDay = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-column: 1 / span 6;
  column-gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid #dadada;
  transition: ${props => props.theme.transitions.standart};

  &:last-of-type {
    border-bottom: none;
  }

  h4 {
    width: 150px;
    border-right: 1px solid ${props => props.theme.colors.seashell};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .empty {
    margin-left: 4px;
    color: #8d8d8d;
  }
`;

const StyledScheduleRowGroup = styled.div`
  display: grid;
  row-gap: 8px;
`;

const StyledDayTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  line-height: 17px;

  & > span {
    min-width: 16px;
    max-height: 16px;
    padding: 0 2px;
    margin-right: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 2px;
  }
`;
