import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import { CalendarItem } from '../calendar.d';
import { BUSINESS_HOURS_START } from '../consts';

interface EventDurationProps extends React.HTMLAttributes<HTMLDivElement> {
  pixelsPerMinute: number;
  data: CalendarItem;
}

const EventDuration = React.memo(
  React.forwardRef<HTMLDivElement, EventDurationProps>(
    ({ className, style, pixelsPerMinute, data, ...props }, ref) => {
      const { start, end, title, address, docId, projectType } = data;
      const layout = useLayout();
      if (!end) {
        console.warn('Invalid calendar event:', data);
        return null;
      }
      const businessHoursStartMoment = dayjs(start).hour(BUSINESS_HOURS_START).minute(0);
      const isPast = end.isBefore(dayjs());
      const duration = end.diff(start, 'minutes');
      const height = duration * pixelsPerMinute;
      const minutesFromStart = start.diff(businessHoursStartMoment, 'minutes');
      const offsetTop = minutesFromStart * pixelsPerMinute;
      const closing = title.toLowerCase().includes('closing');

      return (
        <StyledEventDuration
          ref={ref}
          style={{ top: offsetTop, height, ...style }}
          title={title}
          className={clsx('event-duration', { past: isPast }, className)}
          {...props}
          data-cy={`event_duration_${data.id}`}>
          {closing ? (
            <div className={clsx('closing', { confirmed: docId })}>
              <strong>{projectType}&nbsp;</strong>
              Closing
            </div>
          ) : (
            <div className="name" data-cy={`event_duration_title_${data.id}`}>
              <br />
              {title}
            </div>
          )}
          {layout !== 'mobile' && (
            <div className="address" data-cy={`event_duration_address_${data.id}`}>
              {address}
            </div>
          )}
          {data.personalEvent && <div className="personal-event">Personal event</div>}
        </StyledEventDuration>
      );
    }
  )
);

export default EventDuration;

const StyledEventDuration = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 21px;
  background: #d7e6ff;
  color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  border-radius: 3px;
  padding: 2px 3px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    z-index: 1000 !important;
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: '';
    background: linear-gradient(to top, #d7e6ff 10%, #ffffff00 25%);
    pointer-events: none;
  }

  &.past {
    background: #f8f8f8;
    border-color: ${props => props.theme.colors.grayDark};

    &:after {
      background: linear-gradient(to top, #f8f8f8 10%, #ffffff00 25%);
    }

    .address,
    .personal-event {
      color: ${props => props.theme.colors.grayDark};
    }
  }

  .closing {
    padding: 0 4px;
    font-size: 10px;
    line-height: 16px;
    color: ${props => props.theme.colors.blue};
    border-radius: ${props => props.theme.misc.borderRadius};
    border: 1px solid ${props => props.theme.colors.blue};
    text-transform: capitalize;
    margin-bottom: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.confirmed {
      background: ${props => props.theme.colors.blue};
      color: #ffffff;
    }
  }

  .name,
  .address,
  .personal-event {
    font-size: 10px;
    line-height: 12px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .name {
    white-space: nowrap;
  }

  .address,
  .personal-event {
    color: #000000;
  }
`;
