import React from 'react';
import { Router } from '@reach/router';
import CalendarPage from 'views/CalendarPage';
import PrivateRoute from 'components/PrivateRoute';
import NoSSR from 'components/NoSSR';
import Workspace from 'workspace';

const Calendar = () => {
  return (
    <NoSSR>
      <Workspace>
        <Router>
          <PrivateRoute path="/calendar" component={CalendarPage} />
          <PrivateRoute path="/calendar/:view" component={CalendarPage} />
        </Router>
      </Workspace>
    </NoSSR>
  );
};

export default Calendar;
