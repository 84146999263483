import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import pluralize from 'pluralize';

import styled from '@emotion/styled';
import { CalendarItem } from '../calendar';
import { EventsListModalData } from '.';

interface DateCellProps extends React.HTMLAttributes<HTMLDivElement> {
  date: dayjs.Dayjs;
  active?: boolean;
  past?: boolean;
  outside?: boolean;
  events: CalendarItem[];
  onEventsSelect: (data: EventsListModalData) => void;
}

const DateCell: React.FC<DateCellProps> = React.memo(
  ({ className, date, active, past, outside, events, onEventsSelect, ...props }) => {
    const deadlines: CalendarItem[] = [];
    const tasks: CalendarItem[] = [];
    const customEvents: CalendarItem[] = [];
    events.forEach(event => {
      switch (event.dateType) {
        case 'deadline':
          deadlines.push(event);
          break;
        case 'task':
          tasks.push(event);
          break;
        case 'event':
          customEvents.push(event);
          break;
      }
    });

    const customEventsClamp = [...customEvents];
    if (customEventsClamp.length > 4) customEventsClamp.length = 3;
    const moreEvents = customEvents.length - customEventsClamp.length;

    const handleButtonClick = e => {
      const { value } = e.target;

      let items;
      switch (value) {
        case 'deadline':
          items = deadlines;
          break;
        case 'task':
          items = tasks;
          break;
        case 'event':
          items = customEvents;
          break;
      }

      onEventsSelect({ target: e.target, type: value, items, date });
    };

    const handleEventClick = (e, event) => {
      const target = document.getElementById(`event-${event.id}`) || e.target;
      onEventsSelect({ target, type: 'event', items: [event] });
    };

    return (
      <StyledDateCell
        className={clsx('cell', { active, past, outside }, className)}
        role="button"
        {...props}>
        <div className="cell-top">
          <span className="date">{date.format('D')}</span>
          {deadlines.length > 0 && (
            <button
              value="deadline"
              onClick={handleButtonClick}
              className="deadlines"
              data-cy="button_select_deadline">
              {deadlines.length} {pluralize('deadline', deadlines.length)}
            </button>
          )}
          {tasks.length > 0 && (
            <button
              value="task"
              onClick={handleButtonClick}
              className="tasks"
              data-cy="button_select_task">
              {tasks.length} {pluralize('task', tasks.length)}
            </button>
          )}
        </div>

        {customEventsClamp.map(event => (
          <div
            key={event.id}
            id={`event-${event.id}`}
            role="button"
            onClick={e => handleEventClick(e, event)}
            className="custom-event"
            data-cy={`button_select_custom_event_${event.id}`}>
            {event.title.toLowerCase().includes('closing') ? (
              <span className={clsx('closing', { confirmed: event.docId })}>
                <strong>{event.projectType}&nbsp;</strong>
                Closing
              </span>
            ) : (
              <>
                <div className="event-type" />
                <span className="title">{event.title}</span>
              </>
            )}
          </div>
        ))}
        {customEvents.length > 4 && (
          <button
            value="event"
            onClick={handleButtonClick}
            className="button-more-events"
            data-cy="button_more_events">
            {moreEvents} {pluralize('more event', moreEvents)}
          </button>
        )}
      </StyledDateCell>
    );
  }
);

export default DateCell;

const StyledDateCell = styled.td`
  padding: 8px;
  height: calc(100% / 6);

  &.active {
    height: calc(100% + 2px);
    display: block;
    border-color: #000000;
  }

  .cell-top {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;

    .date {
      font-weight: 500;
      margin-right: 5px;
    }

    .deadlines,
    .tasks {
      border: 1px solid;
      padding: 0 4px;
      background: none;
      border-radius: ${props => props.theme.misc.borderRadius};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 39%;
      cursor: pointer;
    }

    .deadlines {
      color: ${props => props.theme.colors.red};
      border-color: ${props => props.theme.colors.red};
      margin-right: 5px;
    }

    .tasks {
      color: ${props => props.theme.colors.lightGreen};
      border-color: ${props => props.theme.colors.lightGreen};
    }
  }

  &.past {
    .cell-top {
      .date {
        color: ${props => props.theme.colors.grayDark};
      }

      .deadlines,
      .tasks {
        color: ${props => props.theme.colors.grayDark};
      }
    }

    .custom-event {
      .title {
        color: ${props => props.theme.colors.grayDark};
      }
    }
  }

  &.outside {
    .cell-top .date {
      color: ${props => props.theme.colors.gray};
    }
  }

  .custom-event {
    display: flex;
    overflow: hidden;
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;

    .event-type {
      min-width: 4px;
      border-radius: ${props => props.theme.misc.borderRadius};
      margin-right: 8px;
      background: ${props => props.theme.colors.blue};
      white-space: nowrap;
      color: #ffffff;
      flex: 0;

      .title {
        background: #ffffff;
        border: none;
      }

      &:not(:empty) {
        min-width: initial;
      }
    }

    .closing {
      display: inline-block;
      padding: 0 4px;
      background: #ffffff;
      border: 1px solid ${props => props.theme.colors.blue};
      border-radius: ${props => props.theme.misc.borderRadius};
      color: ${props => props.theme.colors.blue};

      strong {
        text-transform: capitalize;
      }

      &.confirmed {
        background: ${props => props.theme.colors.blue};
        color: #ffffff;
      }
    }

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .button-more-events {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayDark};
    border: none;
    background: none;
    padding: 0;
    margin-top: 4px;
    cursor: pointer;
  }
`;
