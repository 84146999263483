import React, { useState, useContext, useMemo } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import { getWeeks, getWeekdays } from '../calendarHelpers';
import CalendarContext from '../CalendarContext';
import { CalendarTooltip, CalendarEventsList } from '../components';
import CalendarMiniature from '../CalendarMiniature';
import { CalendarItem, CalendarItemType } from '../calendar';
import DateCell from './DateCell';
import CalendarEventCompact from './CalendarEventCompact';

const sortEvents = (event: CalendarItem) => {
  switch (event.dateType) {
    case 'event':
      return -1;
    case 'deadline':
      return 0;
    case 'task':
      return 1;
  }
};

type EventsListModalData = {
  target: EventTarget;
  type: CalendarItemType;
  items: CalendarItem[];
  date?: dayjs.Dayjs;
};

const MonthViewTable: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <StyledMonthViewTable className={clsx('month-view-table', className)} {...props}>
      <table>{children}</table>
    </StyledMonthViewTable>
  );
};

interface MonthViewProps extends React.HTMLAttributes<HTMLDivElement> {
  showCalendarMiniature: boolean;
}

const MonthView: React.FC<MonthViewProps> = () => {
  const layout = useLayout();
  const { startDate, calendarEvents, selectedDate } = useContext(CalendarContext);
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);
  const [eventsPopupData, setEventsPopupData] = useState<EventsListModalData>();
  const openEventsListModal = Boolean(eventsPopupData);
  const events = useMemo(() => {
    return layout === 'mobile'
      ? calendarEvents.filter(event => event.start.isSame(selectedDate, 'day')).sort(sortEvents)
      : calendarEvents.sort(sortEvents);
  }, [calendarEvents, layout, selectedDate]);
  const noEvents = events.length === 0;
  const weeks = useMemo(() => getWeeks(startDate, 6), [startDate]);
  const weekdays = getWeekdays(dayjs());

  let eventsListModalTitle;
  switch (eventsPopupData?.type) {
    case 'task':
      eventsListModalTitle = `All tasks - ${eventsPopupData.date!.format('ddd, MMM DD')}`;
      break;
    case 'deadline':
      eventsListModalTitle = `All deadlines - ${eventsPopupData.date!.format('ddd, MMM DD')}`;
      break;
    case 'event':
      eventsListModalTitle = eventsPopupData.date
        ? `All events - ${eventsPopupData.date!.format('ddd, MMM DD')}`
        : 'Event';
  }

  const handleEventClick = () => {
    setEventsPopupData(undefined);
  };

  const getDateEvents = date => events.filter(event => event.start.isSame(date, 'day'));

  switch (layout) {
    case 'mobile':
      return (
        <StyledMonthViewMobile className="month-layout mobile">
          <CalendarMiniature events={calendarEvents} />
          <div className="month-view-head">
            <span className="interval">All - {selectedDate.format('MMM D, YYYY ')}</span>
            {!noEvents && (
              <Button
                onClick={() => setShowDetailedInfo(value => !value)}
                className="button-toggle-info"
                data-cy="button_show_details">
                {showDetailedInfo ? 'Less info' : 'More info'}
              </Button>
            )}
          </div>

          {noEvents ? (
            <div className="no-events">Nothing scheduled for this day</div>
          ) : (
            <>
              {showDetailedInfo ? (
                <div className="events-list scrollable">
                  <CalendarEventsList items={events} />
                </div>
              ) : (
                <div className="events-list compact scrollable">
                  {events.map(event => (
                    <CalendarEventCompact
                      key={`${event.dateType}_${event.id}`}
                      data={event}
                      onClick={handleEventClick}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </StyledMonthViewMobile>
      );
    default:
      return (
        <StyledMonthViewDesktop className="month-layout desktop scrollable">
          <MonthViewTable className="weekdays">
            <thead>
              <tr>
                {weekdays.map(weekday => (
                  <th
                    key={weekday.unix()}
                    className={clsx({ today: weekday.isSame(dayjs(), 'date') })}
                    data-key={`weekday_${weekday.unix()}`}>
                    {weekday.format('ddd')}
                  </th>
                ))}
              </tr>
            </thead>
          </MonthViewTable>

          <MonthViewTable className="dates">
            <tbody>
              {weeks.map((week, idx) => (
                <tr key={idx} className="week">
                  {week.map((day, idx) => (
                    <DateCell
                      key={idx}
                      date={day}
                      events={getDateEvents(day)}
                      active={day.isSame(dayjs(), 'date')}
                      past={day.isBefore(dayjs(), 'date')}
                      outside={!day.isSame(startDate, 'month')}
                      onEventsSelect={setEventsPopupData}
                      data-cy={`date_cell_${day.format('MM/DD/YYYY')}`}>
                      {day.format('D')}
                    </DateCell>
                  ))}
                </tr>
              ))}
            </tbody>
          </MonthViewTable>
          <CalendarTooltip
            target={eventsPopupData?.target}
            open={openEventsListModal}
            tooltipTitle={eventsListModalTitle}
            onClose={() => setEventsPopupData(undefined)}>
            <CalendarEventsList
              items={eventsPopupData?.items}
              onEmpty={() => setEventsPopupData(undefined)}
            />
          </CalendarTooltip>
        </StyledMonthViewDesktop>
      );
  }
};

export default MonthView;

const StyledMonthViewMobile = styled.div`
  display: flex;
  flex-direction: column;

  .month-view-head {
    margin-bottom: 12px;

    .interval {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .button-toggle-info {
      float: right;
      height: 24px;
      color: #000000;
      border-color: #000000;
      background: #ffffff;
    }
  }

  .events-list {
    padding-right: 4px;
  }

  .no-events {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colors.grayDark};
  }

  .calendar-miniature {
    margin-bottom: 12px;
  }
`;

const StyledMonthViewDesktop = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .weekdays {
    flex-shrink: 0;
    margin-bottom: 8px;
    position: sticky;
    top: 0;
    z-index: 1;

    th {
      vertical-align: middle;
      text-align: left;
      height: 32px;
      padding: 0 8px;
      background: #ffffff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};

      &.today {
        color: #000000;
      }
    }
  }

  .dates {
    td {
      height: 121px;
      vertical-align: top;
    }
  }
`;

const StyledMonthViewTable = styled.div`
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: ${props => props.theme.misc.borderRadius};
  box-sizing: border-box;

  td,
  th {
    border: 1px solid #f1f1f1;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
  }
`;
