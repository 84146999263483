import React, { useState, useEffect } from 'react';

import { CalendarItem } from '../calendar';
import CalendarEvent from './CalendarEvent';

interface CalendarEventsListProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: CalendarItem[];
  onEmpty?: () => void;
}

const CalendarEventsList: React.FC<CalendarEventsListProps> = ({
  items: itemsProp = [],
  onEmpty
}) => {
  const [items, setItems] = useState<CalendarItem[]>([]);

  useEffect(() => {
    setItems(itemsProp);
  }, [items, itemsProp]);

  const handleHide = id => {
    const newItems = items.filter(item => item.id !== id);
    setItems(newItems);

    if (newItems.length === 0 && onEmpty) {
      onEmpty();
    }
  };

  return (
    <>
      {items.map(item => (
        <CalendarEvent key={`${item.dateType}_${item.id}`} data={item} onHide={handleHide} />
      ))}
    </>
  );
};

export default CalendarEventsList;
