import React, { useState, useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { capitalize } from 'lodash';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { CalendarItem } from '../calendar';
import DropdownMenu from './DropdownMenu';

interface IComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  data: CalendarItem;
  onEdit: (value: CalendarItem) => void;
  onHide?: (value: CalendarItem) => void;
  onComplete?: (value: CalendarItem) => void;
}

const EventsListItemMobile: React.FC<IComponentProps> = ({
  data,
  onEdit,
  onHide,
  onComplete,
  ...props
}) => {
  const {
    id,
    title,
    assignee,
    start,
    end,
    address,
    location,
    dateType,
    deadlineType,
    projectType,
    editable,
    visible,
    status,
    docId,
    personalEvent,
    isExternal
  } = data;
  const [openEvent, setOpenEvent] = useState<boolean>(false);
  const startDate = start.isValid() ? start.format('hh:mm A') : null;
  const endDate = end?.isValid() ? end.format('hh:mm A') : null;
  const isCompletedTask = dateType === 'task' && status === 'Completed';
  const isClosingDate = deadlineType?.includes('closing_date');
  const isClosingEvent = dateType === 'event' && title.toLowerCase().includes('closing');
  const eventTitle = useMemo(
    () =>
      isClosingEvent ? (
        <span className={clsx('closing', { confirmed: docId })}>
          <span className="project-type">{capitalize(projectType)}&nbsp;</span>
          {title}
        </span>
      ) : (
          <span>{title}</span>
        ),
    [title, isClosingEvent, docId, projectType]
  );

  const dropdownOptions = useMemo(() => {
    const options = [
      {
        value: 'hide',
        text: (
          <>
            <Icon name={visible ? 'eye' : 'eye-crossed'} /> {visible ? 'Hide' : 'Show'}
          </>
        )
      }
    ];

    if (dateType === 'task')
      options.unshift({
        value: 'complete',
        text: (
          <>
            <Icon name="check" className={clsx({ done: isCompletedTask })} />
            {isCompletedTask ? 'Mark as Not Done' : 'Mark as Done'}
          </>
        )
      });

    if (editable)
      options.push({
        value: 'edit',
        text: (
          <>
            <Icon name="pen" />
            Edit
          </>
        )
      });

    return options;
  }, [dateType, editable, isCompletedTask, visible]);

  const handleButtonClick = e => {
    e.stopPropagation();

    switch (e.target.value) {
      case 'complete':
        onComplete!(data);
        break;
      case 'hide':
        onHide!(data);
        break;
      case 'edit':
        onEdit(data);
        break;
      default:
        break;
    }
  };

  const handleEventClick = e => {
    e.stopPropagation();
    setOpenEvent(v => !v);
  };

  switch (dateType) {
    case 'deadline':
      return (
        <StyledDeadlineRow
          {...props}
          data-cy={`deadline_${id}`}
          className={clsx({ closing: isClosingDate })}>
          <DropdownMenu
            options={dropdownOptions}
            onOptionClick={handleButtonClick}
            className="menu"
            target={targetProps => (
              <Button simple data-cy={`button_edit_task_${id}`} {...targetProps}>
                <Icon name="menu-dots" />
              </Button>
            )}
            data-cy={`dropdown_task_menu_${id}`}
          />
          <span className="title">{title}</span>
          <span className="project">{address}</span>
        </StyledDeadlineRow>
      );
    case 'task':
      return (
        <StyledTaskRow
          {...props}
          className={clsx({ completed: isCompletedTask })}
          data-cy={`task_${id}`}>
          <span>
            <span className="title">{title}</span>
            {!isExternal && (
              <span className="complete-and-edit">
                {isCompletedTask && <Icon name="check" />}
                <DropdownMenu
                  options={dropdownOptions}
                  onOptionClick={handleButtonClick}
                  className="menu"
                  target={targetProps => (
                    <Button simple data-cy={`button_edit_task_${id}`} {...targetProps}>
                      <Icon name="menu-dots" />
                    </Button>
                  )}
                  data-cy={`dropdown_task_menu_${id}`}
                />
              </span>
            )}
          </span>
          <span className="project">{address}</span>
          <span className={clsx('assigned', { muted: !assignee })}>
            <Icon name="user" /> {assignee || 'Not assigned'}
          </span>
        </StyledTaskRow>
      );
    case 'event':
      return (
        <StyledEventRow
          {...props}
          className={clsx({ open: openEvent })}
          data-cy={`custom_event_${id}`}>
          <div className="event-head">
            <Icon
              name="chevron"
              onClick={handleEventClick}
              data-cy={`button_open_accordeon_${id}`}
            />
            <span className="title">{eventTitle}</span>
            {openEvent && (
              <Button
                simple
                value="edit"
                onClick={handleButtonClick}
                data-cy={`button_edit_event_${id}`}>
                <Icon name="pen" />
              </Button>
            )}
            <span className="project">{personalEvent ? 'Personal Event' : address}</span>
            <span className="time">
              {startDate && (
                <>
                  <Icon name="time" />
                  <span className="muted">{start.format('ddd, MMM DD')}</span>
                  &nbsp;
                  {endDate ? `${startDate} - ${endDate}` : startDate}
                </>
              )}
            </span>
          </div>
          <CSSTransition in={openEvent} timeout={{ appear: 200 }} classNames="fade" unmountOnExit>
            <div className="event-body">
              <span className={clsx('assigned', { muted: !assignee })}>
                <Icon name="user" /> {assignee || 'Not assigned'}
              </span>
              {location && (
                <span className="location">
                  <Icon name="location" />
                  {location}
                </span>
              )}
              {docId && (
                <a
                  onClick={e => e.stopPropagation()}
                  href={`/xchange/v1/documents/document/${docId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="document">
                  <Icon name="document-fill" />
                  <span>
                    Closing Statement
                    <small>Document</small>
                  </span>
                </a>
              )}
            </div>
          </CSSTransition>
        </StyledEventRow>
      );
    default:
      return null;
  }
};

export default React.memo(EventsListItemMobile);

const StyledEventsListItemMobile = styled.div`
  margin-bottom: 4px;
  padding: 12px 12px 12px 20px;
  border: 1px solid ${props => props.theme.colors.alto};
  border-radius: ${props => props.theme.misc.borderRadius};

  &:before {
    content: '';
    position: absolute;
    width: 4px;
    left: 8px;
    top: 12px;
    bottom: 12px;
    border-radius: 4px;
  }

  .muted {
    color: ${props => props.theme.colors.grayDark};
  }

  .title {
    max-width: 80%;
    margin-bottom: 4px;
    font-weight: 500;

    & > span {
      border-radius: ${props => props.theme.misc.borderRadius};
    }

    & > span.closing:not(.confirmed) {
      border: 1px solid ${props => props.theme.colors.blue};
      background: #fff;
      color: ${props => props.theme.colors.blue};
    }

    & > span.closing.confirmed {
      border: 1px solid ${props => props.theme.colors.blue};
      background: ${props => props.theme.colors.blue};
      color: #fff;
    }

    .project-type {
      font-weight: bold;
    }
  }

  & > span {
    display: flex;
    flex-flow: row wrap;
    word-break: break-word;

    .icon {
      margin-right: 10px;
    }

    &.location .icon {
      margin-right: 8px;
    }
  }

  .project,
  .location,
  .document {
    display: flex;
    flex-basis: 100%;
  }

  .assigned {
    display: flex;
    flex-basis: 100%;
    align-items: center;
  }

  .project {
    color: ${props => props.theme.colors.grayDark};
  }

  .time {
    display: flex;
    white-space: nowrap;
  }

  .assigned,
  .time {
    margin-bottom: 8px;
  }

  .document {
    margin-top: 8px;
    padding: 6px;
    text-decoration: none;
    color: #000;
    border: 1px solid ${props => props.theme.colors.gray};
    border-radius: ${props => props.theme.misc.borderRadius};

    .icon {
      margin: 2px;
      margin-right: 11px;
    }

    small {
      display: block;
      font-size: 10px;
      line-height: 12px;
      color: ${props => props.theme.colors.grayDark};
    }
  }

  .icon {
    flex-shrink: 0;
    fill: ${props => props.theme.colors.grayDark};
  }

  .button {
    float: right;
    &:hover {
      background: ${props => props.theme.colors.seashell};
    }

    .icon {
      pointer-events: none;
    }
  }
`;

const StyledDeadlineRow = styled(StyledEventsListItemMobile)`
  position: relative;
  font-size: 12px;

  &:before {
    background: ${props => props.theme.colors.red};
  }

  &.closing {
    .title {
      display: inline-flex;
      padding: 0 4px 0 4px;
      font-size: 12px;
      color: #fff;
      background: ${props => props.theme.colors.red};
      border-radius: ${props => props.theme.misc.borderRadius};
    }
  }
`;

const StyledTaskRow = styled(StyledEventsListItemMobile)`
  position: relative;
  font-size: 12px;

  &:before {
    background: ${props => props.theme.colors.green};
  }

  .title {
    margin-right: auto;
  }

  .complete-and-edit {
    justify-content: flex-start;

    .icon-check {
      fill: ${props => props.theme.colors.green};
    }

    .icon-menu-dots {
      margin-right: 0;
    }
  }

  .project {
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }

  &.completed {
    .title {
      text-decoration: line-through;
    }
  }

  .dropdown-option {
    .done path {
      fill: ${props => props.theme.colors.green};
    }
  }
`;

const StyledEventRow = styled(StyledEventsListItemMobile)`
  position: relative;

  &:before {
    background: ${props => props.theme.colors.blue};
  }

  .event-head {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    font-size: 12px;
    font-weight: normal;

    & > span {
      align-items: center;
      word-break: break-word;
    }

    .label {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      flex: 1;
    }

    .button.simple {
      margin-left: auto;
      margin-right: 26px;
      width: 16px;
      height: 16px;

      .icon {
        margin-right: 0;
      }
    }

    span > .icon {
      margin-right: 10px;
      transform: none;
    }

    .icon-chevron {
      margin-right: 0;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      fill: #000;
      transform: rotate(0);
      transition: ${props => props.theme.transitions.standart};
    }
  }

  .event-body {
    margin-bottom: 0;

    span > .icon {
      margin-right: 8px;
    }
  }

  &.open {
    .event-head {
      .icon {
        transform: none;
      }

      .icon-chevron {
        fill: ${props => props.theme.colors.red};
        transform: rotate(-180deg);
      }
    }
  }

  &.open {
    border: 1px solid #000;
  }

  .project {
    flex-basis: 100%;
  }

  .project {
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 1px solid ${props => props.theme.colors.seashell};
  }
`;
