import React, { useState, useRef, useEffect } from 'react';

import styled from '@emotion/styled';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import { CalendarItem } from '../calendar';
import CalendarTooltip from './CalendarTooltip';
import CalendarEventsList from './CalendarEventsList';
import clsx from 'clsx';
import useLayout from 'hooks/useLayout';

interface HiddenEventsButtonProps {
  events: CalendarItem[];
}

const HiddenEventsButton: React.FC<HiddenEventsButtonProps> = ({ events }) => {
  const layout = useLayout();
  const buttonRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (events.length === 0) {
      setOpen(false);
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [events.length]);

  const handleOutsideClick = e => {
    const optionsMenu = document.querySelector('.options-menu');
    if (optionsMenu && optionsMenu.contains(e.target)) return;
    setOpen(false);
  };

  return (
    <>
      <StyledHiddenEventsButton
        ref={buttonRef}
        onClick={() => setOpen(v => !v)}
        className={clsx('hidden-events-button', { hidden })}>
        <Icon name="eye-crossed" />
        {events.length}
        <Icon name="chevron" style={{ transform: open ? 'rotate(180deg)' : undefined }} />
      </StyledHiddenEventsButton>
      {layout === 'mobile' ? (
        <Modal
          renderContainer={StyledFormatsModalContainer}
          modalTitle="Hidden Tasks and Deadlines"
          open={open}
          onClose={() => setOpen(false)}
          data-cy="modal_hidden_events">
          <CalendarEventsList items={events} onEmpty={() => setOpen(false)} />
        </Modal>
      ) : (
        <CalendarTooltip
          open={open}
          unmountChildren={false}
          target={buttonRef.current}
          tooltipTitle="Hidden Tasks and Deadlines"
          placement="bottom-end"
          onClose={handleOutsideClick}>
          <CalendarEventsList items={events} onEmpty={() => setOpen(false)} />
        </CalendarTooltip>
      )}
    </>
  );
};

export default React.memo(HiddenEventsButton);

const StyledHiddenEventsButton = styled.button`
  display: flex;
  background: none;
  border: 1px solid #000;
  border-radius: ${props => props.theme.misc.borderRadius};
  transition: ${props => props.theme.transitions.standart};
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  height: 32px;
  outline: none;

  &:hover {
    border-color: ${props => props.theme.colors.red};
  }

  &.hidden {
    display: none;
  }

  .icon-eye-crossed {
    fill: ${props => props.theme.colors.grayDark};
    margin-right: 4px;
  }

  .icon-chevron {
    fill: ${props => props.theme.colors.red};
    margin-left: 4px;
  }
`;

const StyledFormatsModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    display: block;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    .xp-modal {
      margin: 0;
      border-radius: 0;
    }
  }
`;
