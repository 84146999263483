import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarItem } from '../calendar.d';
import TimeLine from './TimeLine';
import EventDuration from './EventDuration';

interface FloatingEventsProps extends React.HTMLAttributes<HTMLDivElement> {
  events: CalendarItem[];
  weekDays?: Dayjs[];
  showTimeLine?: boolean;
  pixelsPerMinute: number;
  onEventClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, event: CalendarItem) => void;
}

const getDateEventGroups = (events: CalendarItem[]) => {
  const groups: {
    key: any;
    start: Dayjs;
    end: Dayjs;
    events: CalendarItem[];
  }[] = [];
  events
    .sort((a, b) => {
      if (a.start.isBefore(b.start)) return -1;
      if (a.start.isAfter(b.start)) return 1;
      return 0;
    })
    .forEach(event => {
      const groupIdx = groups.findIndex(
        group =>
          group.start.isSame(event.start) ||
          group.start.isBetween(event.start, event.end!) ||
          group.end.isSame(event.end!) ||
          group.end.isBetween(event.start, event.end!)
      );
      if (groupIdx !== -1) {
        groups[groupIdx]?.events.push(event);
        if (groups[groupIdx].end.isBefore(event.end!)) groups[groupIdx].end = event.end!;
      } else {
        groups.push({
          key: event.start.unix(),
          start: event.start,
          end: event.end!,
          events: [event]
        });
      }
    });

  return groups;
};

const FloatingEvents: React.FC<FloatingEventsProps> = ({
  className,
  events,
  weekDays,
  showTimeLine = true,
  pixelsPerMinute,
  onEventClick
}) => {
  const [time, setTime] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => setTime(dayjs()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const content = useMemo(() => {
    if (weekDays) {
      return (
        <div className="weekdays">
          {weekDays.map(weekday => (
            <div key={weekday.unix()} className="weekday-column">
              {showTimeLine && time.isSame(weekday, 'day') && (
                <TimeLine time={time} pixelsPerMinute={pixelsPerMinute} />
              )}
              {getDateEventGroups(events.filter(event => event.start.isSame(weekday, 'day'))).map(
                item => (
                  <div key={item.key} className="events-group">
                    {item.events.map((event, idx) => (
                      <EventDuration
                        id={`event-${event.id}`}
                        key={event.id}
                        data={event}
                        pixelsPerMinute={pixelsPerMinute}
                        style={{
                          width: `calc(100% / ${item.events.length})`,
                          left: `calc(100% / ${item.events.length} * ${idx} - 1px)`
                        }}
                        onClick={e => onEventClick(e, event)}
                      />
                    ))}
                  </div>
                )
              )}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className="weekday-column">
        {showTimeLine && <TimeLine time={time} pixelsPerMinute={pixelsPerMinute} />}
        {getDateEventGroups(events).map(item => (
          <div key={item.key} className="events-group">
            {item.events.map((event, idx) => (
              <EventDuration
                id={`event-${event.id}`}
                key={event.id}
                data={event}
                pixelsPerMinute={pixelsPerMinute}
                style={{
                  width: `calc(100% / ${item.events.length})`,
                  left: `calc(100% / ${item.events.length} * ${idx} - 1px)`
                }}
                onClick={e => onEventClick(e, event)}
              />
            ))}
          </div>
        ))}
      </div>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, pixelsPerMinute, weekDays]);

  return (
    <StyledFloatingEvents className={clsx('floating-events', className)}>
      {content}
    </StyledFloatingEvents>
  );
};

export default FloatingEvents;

const StyledFloatingEvents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .weekdays {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .weekday-column {
    width: 100%;
    position: relative;

    .events-group {
      display: flex;
    }
  }
`;
